<template>
  <div class="page-wrapper">
    <TheSidebar />
    <div v-show="!spinnerActive1 && !spinnerActive2 && !spinnerActive3" class="right-content-wrapper">
      <v-data-table
        ref="table"
        :headers="headers"
        :items="items"
        :items-per-page="500"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDirection"
        :page.sync="page"
        style="width: 100%"
        @update:options="getItems"
        :hide-default-footer="true"
        :fixed-header="true"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Specialities - AC-Lists</v-toolbar-title>
          </v-toolbar>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="getItems">No data</v-btn>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td v-for="header in headers" :key="header.id">
              <div v-if="typeof calculateTableValue(header, item) === 'string'">
                {{calculateTableValue(header, item)}}
              </div>
              <div
                v-else-if="calculateTableValue(header, item)"
                v-on:click="activateDeactivateListSpeciality(header, item)"
                class="button-for-switch"
                style="background-color: green"
              >
                <v-icon>mdi-check-bold</v-icon>
              </div>
              <div
                v-else
                v-on:click="activateDeactivateListSpeciality(header, item)"
                class="button-for-switch"
                style="background-color: rgb(223 223 223)"
              >
                <v-icon>mdi-close</v-icon>
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
    <div v-show="spinnerActive1 || spinnerActive2 || spinnerActive3" style="height: 100%; width: 100%; background-color: white; display: flex; justify-content: center; align-items: center">
      <v-progress-circular
        indeterminate
        color="primary"
        :size="140"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import { fetchACLists, fetchSpecialities, activateDeactivateListSpecialityAPI } from "@/services"
import TheSidebar from "@/components/TheSidebar"

export default {
  name: "ACConnectsLists",
  components: { TheSidebar },
  data() {
    return {
      valid: false,
      perPage: 500,
      sortDirection: false,
      page: 1,
      total: 0,
      sortBy: "title",
      spinnerActive1: false,
      spinnerActive2: false,
      spinnerActive3: false,
      dialog: false,
      dialogDelete: false,
      expertImage: false,
      headers: [
        { text: "Title", align: "start", value: "title" }
      ],
      items: [],
      website_id: "",
      editedIndex: -1,
      editedItem: {
        first_name: "",
        last_name: "",
        photo: null,
        biography: "",
        type: "",
        license_prefix: "",
        country: "",
      },
      defaultItem: {
        first_name: "",
        last_name: "",
        photo: null,
        biography: "",
        type: "",
        license_prefix: "dr.",
        country: "",
      },
      rules: {
        required: (v) => !!v || "Required.",
        requireLength: (v) => (v != undefined && v.length > 0) || "Required",
        sizes: (files) =>
          !files ||
          !files.some((file) => file.size > 20e6) ||
          "Image size should be less than 1 MB!",
        size: (file) =>
          !file || file.size < 20e6 || "Image size should be less than 20 MB!",
        slug: (v) =>
          /^[a-z0-9-]+$/gm.test(v) ||
          "Must be lowercase and with '-' instead of 'whitespace'.",
      },
    }
  },
  mounted() {
    this.getItems()
    this.getACLists()
  },
  methods: {
    async activateDeactivateListSpeciality(header, item) {
      this.spinnerActive3 = true
      const data = {
        speciality_id: item.id,
        ac_list_id: header.value
      }
      await activateDeactivateListSpecialityAPI(data).then(response => {
        this.getItems()
        this.spinnerActive3 = false
      }).catch(() => {
        this.getItems()
        this.spinnerActive3 = false
      })
    },
    calculateTableValue(header, item) {
      if (header.value === 'title') {
        return item.title
      } else {
        if (item.speciality_active_campain.length > 0) {
          let found = false
          item.speciality_active_campain.forEach(element => {
            if (element.ac_list_id == header.value) {
              found = true
            }
          })
          return found
        } else {
          return false
        }
      }
    },
    async getItems() {
      if (this.spinnerActive1) return
      this.items = []
      this.spinnerActive1 = true
      setTimeout(async () => {
        await fetchSpecialities().then((res) => {
          if (res.data) {
            this.items = res.data.data
            // console.log('header, item', header, item)
            // if (item.title.indexOf('*') >= 0) { return false }
            // console.log('this.items 11 ', this.items)
            // debugger
            // this.items = this.items.filter(el => el.name.indexOf('*') < 0)

          }
          this.spinnerActive1 = false
        }).catch((error) => {
          console.log(error)
          this.spinnerActive1 = false
        })
      }, 0)
    },
    async getACLists() {
      this.items = []
      setTimeout(async () => {
        if (this.spinnerActive2) return
        this.spinnerActive2 = true
        await fetchACLists()
          .then((res) => {
            if (res.data) {
              res.data.lists.forEach(element => {
                if (element.name.indexOf('***') < 0) {
                  this.headers.push({ text: element.name, sortable: false, value: element.id })
                }
              })
              this.spinnerActive2 = false
            }
          })
          .catch((error) => {
            console.log(error)
            this.spinnerActive2 = false
          })
      }, 0)
    }
  }
}
</script>

<style scoped>
/* The switch - the box around the slider */
.button-for-switch {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
}
.button-for-switch i {
  color: white;
}
</style>
<style>
.v-data-table__wrapper{
  max-height: calc(100vh - 65px);
  overflow-y: auto !important;
}
</style>